var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "page-title",
        { attrs: { "show-back-btn": "" } },
        [
          _c(
            "template",
            { slot: "btn-inner" },
            [
              _vm.viewEdit()
                ? _c(
                    "debounce-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForms }
                    },
                    [_vm._v(" 提交 ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      ),
      _c("div", { staticClass: "partition-area" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading.detail,
                expression: "loading.detail"
              }
            ],
            staticClass: "partition-area"
          },
          [
            _c(
              "el-form",
              {
                ref: "appForm",
                attrs: {
                  model: _vm.appForm,
                  rules: _vm.rules,
                  "label-width": "180px",
                  "label-suffix": _vm.constants.labelSuffix
                }
              },
              [
                _c(
                  "ics-page-inner",
                  { attrs: { title: "金融产品信息" } },
                  [
                    _vm.$route.params.editMode !== "add"
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "金融产品编码" } },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.utils.isEffectiveCommon(
                                        _vm.appForm.productCode
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "productName",
                              label: "金融产品名称"
                            }
                          },
                          [
                            _c(
                              "ics-item-inner",
                              {
                                attrs: {
                                  prop: _vm.appForm.productName,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入金融产品名称" },
                                  model: {
                                    value: _vm.appForm.productName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.appForm, "productName", $$v)
                                    },
                                    expression: "appForm.productName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("el-col"),
                    !_vm.viewEdit()
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "隶属资方" } },
                              [
                                _c("ics-item-inner", {
                                  attrs: {
                                    prop: _vm.appForm.capName,
                                    format: _vm.utils.isEffectiveCommon
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.viewEdit()
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "capId", label: "隶属资方" } },
                              [
                                _c(
                                  "ics-item-inner",
                                  {
                                    attrs: {
                                      prop: _vm.appForm.capCode,
                                      format: _vm.utils.isEffectiveCommon
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择所属资金方",
                                          filterable: ""
                                        },
                                        on: {
                                          change: function(val) {
                                            return _vm.companyChange(val)
                                          }
                                        },
                                        model: {
                                          value: _vm.appForm.capCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.appForm,
                                              "capCode",
                                              $$v
                                            )
                                          },
                                          expression: "appForm.capCode"
                                        }
                                      },
                                      _vm._l(_vm.capList, function(item) {
                                        return _c("el-option", {
                                          key: item.capCode,
                                          attrs: {
                                            label:
                                              item.capName +
                                              "（" +
                                              item.capCode +
                                              "）",
                                            value: item.capCode
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "productFinancing",
                              label: "产品类型"
                            }
                          },
                          [
                            _c(
                              "ics-item-inner",
                              {
                                attrs: {
                                  prop: _vm.appForm.productFinancing,
                                  format: function(val) {
                                    return _vm.utils.statusFormat(
                                      Number(val),
                                      "productType"
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择产品类型",
                                      filterable: ""
                                    },
                                    model: {
                                      value: _vm.appForm.productFinancing,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.appForm,
                                          "productFinancing",
                                          $$v
                                        )
                                      },
                                      expression: "appForm.productFinancing"
                                    }
                                  },
                                  _vm._l(_vm.constants.productType, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    !_vm.viewEdit()
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "产品模型" } },
                              [
                                _c("ics-item-inner", {
                                  attrs: {
                                    prop: _vm.appForm.productTypeName,
                                    format: _vm.utils.isEffectiveCommon
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.viewEdit()
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "modelId", label: "产品模型" } },
                              [
                                _c(
                                  "ics-item-inner",
                                  {
                                    attrs: {
                                      prop: _vm.appForm.modelId,
                                      format: _vm.utils.isEffectiveCommon
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择产品模型",
                                          filterable: ""
                                        },
                                        on: {
                                          change: function(val) {
                                            return _vm.modelChange(val)
                                          }
                                        },
                                        model: {
                                          value: _vm.appForm.modelId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.appForm,
                                              "modelId",
                                              $$v
                                            )
                                          },
                                          expression: "appForm.modelId"
                                        }
                                      },
                                      _vm._l(_vm.modelList, function(item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label:
                                              item.modelName +
                                              "（" +
                                              item.modelCode +
                                              "）",
                                            value: item.id
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "repaymentMethod",
                              label: "是否支持线上放/还款"
                            }
                          },
                          [
                            _c(
                              "ics-item-inner",
                              {
                                attrs: {
                                  prop: _vm.appForm.repaymentMethod,
                                  format: function(val) {
                                    return _vm.utils.statusFormat(
                                      Number(val),
                                      "isOnLine"
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.appForm.repaymentMethod,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.appForm,
                                          "repaymentMethod",
                                          $$v
                                        )
                                      },
                                      expression: "appForm.repaymentMethod"
                                    }
                                  },
                                  _vm._l(_vm.constants.isOnLine, function(
                                    item
                                  ) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: item.value,
                                        attrs: { label: item.value }
                                      },
                                      [_vm._v(" " + _vm._s(item.label) + " ")]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    Number(_vm.attribute.method) === 1
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { prop: "repayment", label: "还款方式" }
                              },
                              [
                                _c(
                                  "ics-item-inner",
                                  {
                                    attrs: {
                                      prop: _vm.appForm.repayment,
                                      format: function(val) {
                                        return _vm.utils.statusFormat(
                                          String(val),
                                          "repaymentType"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: { change: _vm.changeRepaymentType },
                                        model: {
                                          value: _vm.appForm.repayment,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.appForm,
                                              "repayment",
                                              $$v
                                            )
                                          },
                                          expression: "appForm.repayment"
                                        }
                                      },
                                      _vm._l(
                                        _vm.constants.repaymentType,
                                        function(item) {
                                          return _c(
                                            "el-radio",
                                            {
                                              key: item.value,
                                              attrs: { label: item.value }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.label) + " "
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "serviceChargeMethod",
                              label: "服务费收取方式"
                            }
                          },
                          [
                            _c(
                              "ics-item-inner",
                              {
                                attrs: {
                                  prop: _vm.appForm.serviceChargeMethod,
                                  format: function(val) {
                                    return _vm.utils.statusFormat(
                                      Number(val),
                                      "ownFunds"
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.appForm.serviceChargeMethod,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.appForm,
                                          "serviceChargeMethod",
                                          $$v
                                        )
                                      },
                                      expression: "appForm.serviceChargeMethod"
                                    }
                                  },
                                  _vm._l(_vm.constants.ownFunds, function(
                                    item
                                  ) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: item.value,
                                        attrs: { label: item.value }
                                      },
                                      [_vm._v(" " + _vm._s(item.label) + " ")]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.appForm.repayment === "borrowing"
                      ? _c(
                          "div",
                          [
                            Number(_vm.attribute.amountRange) === 1
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "borrowingInfo.maxAmount",
                                          label: "借贷金额上限(元)"
                                        }
                                      },
                                      [
                                        _c(
                                          "ics-item-inner",
                                          {
                                            attrs: {
                                              prop:
                                                _vm.appForm.borrowingInfo
                                                  .maxAmount,
                                              format: function(val) {
                                                return _vm.utils.moneyFormat(
                                                  Number(val),
                                                  2
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "number",
                                                placeholder:
                                                  "请输入借贷金额上限"
                                              },
                                              model: {
                                                value:
                                                  _vm.appForm.borrowingInfo
                                                    .maxAmount,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.appForm.borrowingInfo,
                                                    "maxAmount",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "appForm.borrowingInfo.maxAmount"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            Number(_vm.attribute.minCycle) === 1
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "免收费期限(天)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.minCycleMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .minCycleMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最小免收费期限"
                                                              },
                                                              on: {
                                                                input: function(
                                                                  v
                                                                ) {
                                                                  return (_vm.appForm.borrowingInfo.minCycleMin = v.replace(
                                                                    /\D|^0/g,
                                                                    ""
                                                                  ))
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .minCycleMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "minCycleMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.minCycleMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.minCycleMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .minCycleMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最大免收费期限"
                                                              },
                                                              on: {
                                                                input: function(
                                                                  v
                                                                ) {
                                                                  return (_vm.appForm.borrowingInfo.minCycleMax = v.replace(
                                                                    /\D|^0/g,
                                                                    ""
                                                                  ))
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .minCycleMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "minCycleMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.minCycleMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.borrowingInfo
                                                        .minCycleMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .borrowingInfo
                                                          .minCycleMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            Number(_vm.attribute.minCycle) === 1
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "借贷期限(天)",
                                          prop: "borrowingInfo.maxPeriod"
                                        }
                                      },
                                      [
                                        _c(
                                          "ics-item-inner",
                                          {
                                            attrs: {
                                              prop:
                                                _vm.appForm.borrowingInfo
                                                  .maxPeriod,
                                              format:
                                                _vm.utils.isEffectiveCommon
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入借贷期限"
                                              },
                                              on: {
                                                input: function(v) {
                                                  return (_vm.appForm.borrowingInfo.maxPeriod = v.replace(
                                                    /\D|^0/g,
                                                    ""
                                                  ))
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.appForm.borrowingInfo
                                                    .maxPeriod,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.appForm.borrowingInfo,
                                                    "maxPeriod",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "appForm.borrowingInfo.maxPeriod"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.dayRate === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "日利率(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.interestRateMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .interestRateMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最小日利率"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .interestRateMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "interestRateMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.interestRateMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.interestRateMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .interestRateMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最大日利率"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .interestRateMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "interestRateMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.interestRateMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.borrowingInfo
                                                        .interestRateMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .borrowingInfo
                                                          .interestRateMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.penaltyInterest === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "罚息(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.penalTYtRateMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .penalTYtRateMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最小罚息"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .penalTYtRateMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "penalTYtRateMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.penalTYtRateMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.penalTYtRateMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .penalTYtRateMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最大罚息"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .penalTYtRateMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "penalTYtRateMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.penalTYtRateMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.borrowingInfo
                                                        .penalTYtRateMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .borrowingInfo
                                                          .penalTYtRateMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.overdue === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "逾期期限" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.overdueMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .overdueMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最小时长"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .overdueMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "overdueMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.overdueMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.overdueMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .overdueMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最大时长"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .overdueMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "overdueMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.overdueMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.borrowingInfo
                                                        .overdueMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .borrowingInfo
                                                          .overdueMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.extension === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "是否支持宽限期" } },
                                      [
                                        _vm.attribute.extension === "1"
                                          ? _c("p", [_vm._v(" 是 ")])
                                          : _vm._e(),
                                        _vm.attribute.extension === "0"
                                          ? _c("p", [_vm._v(" 否 ")])
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.extension === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "宽限期最大时长" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.maxExtensionMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .maxExtensionMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最小时长"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .maxExtensionMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "maxExtensionMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.maxExtensionMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.maxExtensionMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .maxExtensionMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最大时长"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .maxExtensionMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "maxExtensionMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.maxExtensionMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.borrowingInfo
                                                        .maxExtensionMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .borrowingInfo
                                                          .maxExtensionMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.extension === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "宽限期利率(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.extensionRateMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .extensionRateMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最小宽限期利率"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .extensionRateMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "extensionRateMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.extensionRateMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.extensionRateMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .extensionRateMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最大宽限期利率"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .extensionRateMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "extensionRateMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.extensionRateMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.borrowingInfo
                                                        .extensionRateMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .borrowingInfo
                                                          .extensionRateMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.financing === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "融资比例(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.financingMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .financingMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最小融资比例"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .financingMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "financingMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.financingMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.financingMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .financingMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最大融资比例"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .financingMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "financingMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.financingMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.borrowingInfo
                                                        .financingMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .borrowingInfo
                                                          .financingMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.serviceCharge === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "服务费比例(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.serviceChargeMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .serviceChargeMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最小服务费比例"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .serviceChargeMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "serviceChargeMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.serviceChargeMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "borrowingInfo.serviceChargeMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .borrowingInfo
                                                                  .serviceChargeMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最大服务费比例"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .borrowingInfo
                                                                    .serviceChargeMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .borrowingInfo,
                                                                    "serviceChargeMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.borrowingInfo.serviceChargeMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.borrowingInfo
                                                        .serviceChargeMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .borrowingInfo
                                                          .serviceChargeMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.appForm.repayment === "firstRest"
                      ? _c(
                          "div",
                          [
                            Number(_vm.attribute.amountRange) === 1
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "firstRestInfo.maxAmount",
                                          label: "借贷金额上限(元)"
                                        }
                                      },
                                      [
                                        _c(
                                          "ics-item-inner",
                                          {
                                            attrs: {
                                              prop:
                                                _vm.appForm.firstRestInfo
                                                  .maxAmount,
                                              format: function(val) {
                                                return _vm.utils.moneyFormat(
                                                  Number(val),
                                                  2
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "number",
                                                placeholder:
                                                  "请输入借贷金额上限"
                                              },
                                              model: {
                                                value:
                                                  _vm.appForm.firstRestInfo
                                                    .maxAmount,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.appForm.firstRestInfo,
                                                    "maxAmount",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "appForm.firstRestInfo.maxAmount"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            Number(_vm.attribute.minCycle) === 1
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "免收费期限(天)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.minCycleMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .minCycleMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最小免收费期限"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .minCycleMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "minCycleMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.minCycleMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.minCycleMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .minCycleMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最大免收费期限"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .minCycleMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "minCycleMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.minCycleMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.firstRestInfo
                                                        .minCycleMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .firstRestInfo
                                                          .minCycleMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            Number(_vm.attribute.minCycle) === 1
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "借贷期限(天)",
                                          prop: "firstRestInfo.maxPeriod"
                                        }
                                      },
                                      [
                                        _c(
                                          "ics-item-inner",
                                          {
                                            attrs: {
                                              prop:
                                                _vm.appForm.firstRestInfo
                                                  .maxPeriod,
                                              format:
                                                _vm.utils.isEffectiveCommon
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入借贷期限"
                                              },
                                              on: {
                                                input: function(v) {
                                                  return (_vm.appForm.firstRestInfo.maxPeriod = v.replace(
                                                    /\D|^0/g,
                                                    ""
                                                  ))
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.appForm.firstRestInfo
                                                    .maxPeriod,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.appForm.firstRestInfo,
                                                    "maxPeriod",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "appForm.firstRestInfo.maxPeriod"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.dayRate === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "日利率(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.interestRateMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .interestRateMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最小日利率"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .interestRateMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "interestRateMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.interestRateMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.interestRateMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .interestRateMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最大日利率"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .interestRateMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "interestRateMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.interestRateMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.firstRestInfo
                                                        .interestRateMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .firstRestInfo
                                                          .interestRateMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.penaltyInterest === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "罚息(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.penalTYtRateMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .penalTYtRateMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最小罚息"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .penalTYtRateMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "penalTYtRateMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.penalTYtRateMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.penalTYtRateMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .penalTYtRateMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最大罚息"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .penalTYtRateMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "penalTYtRateMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.penalTYtRateMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.firstRestInfo
                                                        .penalTYtRateMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .firstRestInfo
                                                          .penalTYtRateMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.overdue === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "逾期期限" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.overdueMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .overdueMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最小时长"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .overdueMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "overdueMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.overdueMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.overdueMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .overdueMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最大时长"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .overdueMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "overdueMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.overdueMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.firstRestInfo
                                                        .overdueMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .firstRestInfo
                                                          .overdueMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.extension === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "是否支持宽限期" } },
                                      [
                                        _vm.attribute.extension === "1"
                                          ? _c("p", [_vm._v(" 是 ")])
                                          : _vm._e(),
                                        _vm.attribute.extension === "0"
                                          ? _c("p", [_vm._v(" 否 ")])
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.extension === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "宽限期最大时长" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.maxExtensionMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .maxExtensionMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最小时长"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .maxExtensionMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "maxExtensionMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.maxExtensionMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.maxExtensionMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .maxExtensionMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最大时长"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .maxExtensionMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "maxExtensionMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.maxExtensionMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.firstRestInfo
                                                        .maxExtensionMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .firstRestInfo
                                                          .maxExtensionMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.extension === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "宽限期利率(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.extensionRateMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .extensionRateMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最小宽限期利率"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .extensionRateMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "extensionRateMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.extensionRateMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.extensionRateMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .extensionRateMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最大宽限期利率"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .extensionRateMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "extensionRateMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.extensionRateMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.firstRestInfo
                                                        .extensionRateMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .firstRestInfo
                                                          .extensionRateMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.financing === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "融资比例(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.financingMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .financingMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最小融资比例"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .financingMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "financingMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.financingMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.financingMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .financingMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最大融资比例"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .financingMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "financingMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.financingMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.firstRestInfo
                                                        .financingMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .firstRestInfo
                                                          .financingMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.serviceCharge === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "服务费比例(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.serviceChargeMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .serviceChargeMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最小服务费比例"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .serviceChargeMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "serviceChargeMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.serviceChargeMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "firstRestInfo.serviceChargeMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .firstRestInfo
                                                                  .serviceChargeMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最大服务费比例"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .firstRestInfo
                                                                    .serviceChargeMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .firstRestInfo,
                                                                    "serviceChargeMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.firstRestInfo.serviceChargeMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.firstRestInfo
                                                        .serviceChargeMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm
                                                          .firstRestInfo
                                                          .serviceChargeMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.appForm.repayment === "regular"
                      ? _c(
                          "div",
                          [
                            Number(_vm.attribute.amountRange) === 1
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "regularInfo.maxAmount",
                                          label: "借贷金额上限(元)"
                                        }
                                      },
                                      [
                                        _c(
                                          "ics-item-inner",
                                          {
                                            attrs: {
                                              prop:
                                                _vm.appForm.regularInfo
                                                  .maxAmount,
                                              format: function(val) {
                                                return _vm.utils.moneyFormat(
                                                  Number(val),
                                                  2
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "number",
                                                placeholder:
                                                  "请输入借贷金额上限"
                                              },
                                              model: {
                                                value:
                                                  _vm.appForm.regularInfo
                                                    .maxAmount,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.appForm.regularInfo,
                                                    "maxAmount",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "appForm.regularInfo.maxAmount"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            Number(_vm.attribute.minCycle) === 1
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "免收费期限(天)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.minCycleMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .minCycleMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最小免收费期限"
                                                              },
                                                              on: {
                                                                input: function(
                                                                  v
                                                                ) {
                                                                  return (_vm.appForm.regularInfo.minCycleMin = v.replace(
                                                                    /\D|^0/g,
                                                                    ""
                                                                  ))
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .minCycleMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "minCycleMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.minCycleMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.minCycleMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .minCycleMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最大免收费期限"
                                                              },
                                                              on: {
                                                                input: function(
                                                                  v
                                                                ) {
                                                                  return (_vm.appForm.regularInfo.minCycleMax = v.replace(
                                                                    /\D|^0/g,
                                                                    ""
                                                                  ))
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .minCycleMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "minCycleMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.minCycleMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.regularInfo
                                                        .minCycleMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm.regularInfo
                                                          .minCycleMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.dayRate === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "日利率(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.interestRateMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .interestRateMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最小日利率"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .interestRateMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "interestRateMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.interestRateMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.interestRateMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .interestRateMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最大日利率"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .interestRateMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "interestRateMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.interestRateMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.regularInfo
                                                        .interestRateMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm.regularInfo
                                                          .interestRateMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.penaltyInterest === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "罚息(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.penalTYtRateMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .penalTYtRateMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最小罚息"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .penalTYtRateMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "penalTYtRateMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.penalTYtRateMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.penalTYtRateMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .penalTYtRateMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最大罚息"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .penalTYtRateMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "penalTYtRateMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.penalTYtRateMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.regularInfo
                                                        .penalTYtRateMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm.regularInfo
                                                          .penalTYtRateMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.overdue === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "逾期期限" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.overdueMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .overdueMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最小时长"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .overdueMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "overdueMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.overdueMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.overdueMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .overdueMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最大时长"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .overdueMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "overdueMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.overdueMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.regularInfo
                                                        .overdueMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm.regularInfo
                                                          .overdueMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.extension === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "是否支持宽限期" } },
                                      [
                                        _vm.attribute.extension === "1"
                                          ? _c("p", [_vm._v(" 是 ")])
                                          : _vm._e(),
                                        _vm.attribute.extension === "0"
                                          ? _c("p", [_vm._v(" 否 ")])
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.extension === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "宽限期最大时长" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.maxExtensionMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .maxExtensionMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最小时长"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .maxExtensionMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "maxExtensionMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.maxExtensionMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.maxExtensionMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .maxExtensionMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入最大时长"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .maxExtensionMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "maxExtensionMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.maxExtensionMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.regularInfo
                                                        .maxExtensionMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm.regularInfo
                                                          .maxExtensionMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "期数",
                                      prop: "regularInfo.periods"
                                    }
                                  },
                                  [
                                    _c(
                                      "ics-item-inner",
                                      {
                                        attrs: {
                                          prop: _vm.appForm.regularInfo.periods,
                                          format: _vm.utils.isEffectiveCommon
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "请输入期数" },
                                          model: {
                                            value:
                                              _vm.appForm.regularInfo.periods,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.appForm.regularInfo,
                                                "periods",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "appForm.regularInfo.periods"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.attribute.extension === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "宽限期利率(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.extensionRateMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .extensionRateMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最小宽限期利率"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .extensionRateMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "extensionRateMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.extensionRateMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.extensionRateMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .extensionRateMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最大宽限期利率"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .extensionRateMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "extensionRateMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.extensionRateMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.regularInfo
                                                        .extensionRateMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm.regularInfo
                                                          .extensionRateMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.financing === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "融资比例(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.financingMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .financingMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最小融资比例"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .financingMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "financingMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.financingMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.financingMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .financingMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最大融资比例"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .financingMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "financingMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.financingMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.regularInfo
                                                        .financingMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm.regularInfo
                                                          .financingMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.attribute.serviceCharge === "1"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "服务费比例(%)" } },
                                      [
                                        _vm.viewEdit()
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.serviceChargeMin"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .serviceChargeMin,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最小服务费比例"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .serviceChargeMin,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "serviceChargeMin",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.serviceChargeMin"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      "line-height": "32px"
                                                    },
                                                    attrs: { span: 1 }
                                                  },
                                                  [_vm._v(" ~ ")]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 11 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom": "0"
                                                        },
                                                        attrs: {
                                                          prop:
                                                            "regularInfo.serviceChargeMax"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "ics-item-inner",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                _vm.appForm
                                                                  .regularInfo
                                                                  .serviceChargeMax,
                                                              format:
                                                                _vm.utils
                                                                  .isEffectiveCommon
                                                            }
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "最大服务费比例"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.appForm
                                                                    .regularInfo
                                                                    .serviceChargeMax,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.appForm
                                                                      .regularInfo,
                                                                    "serviceChargeMax",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "appForm.regularInfo.serviceChargeMax"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.viewEdit()
                                          ? _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      _vm.appForm.regularInfo
                                                        .serviceChargeMin
                                                    ) +
                                                      " ~ " +
                                                      _vm.utils.isEffectiveCommon(
                                                        _vm.appForm.regularInfo
                                                          .serviceChargeMax
                                                      )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "还款日",
                                      prop: "regularInfo.repaymentDay"
                                    }
                                  },
                                  [
                                    _c(
                                      "ics-item-inner",
                                      {
                                        attrs: {
                                          prop:
                                            _vm.appForm.regularInfo
                                              .repaymentDay,
                                          format: _vm.utils.isEffectiveCommon
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入还款日"
                                          },
                                          model: {
                                            value:
                                              _vm.appForm.regularInfo
                                                .repaymentDay,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.appForm.regularInfo,
                                                "repaymentDay",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "appForm.regularInfo.repaymentDay"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "ics-page-inner",
                  { attrs: { title: "业务覆盖城市" } },
                  [
                    _c("ics-city-inner", {
                      ref: "city",
                      attrs: {
                        "cap-id": _vm.appForm.capId,
                        "city-info": _vm.cityList,
                        "view-city": !_vm.viewEdit()
                      }
                    })
                  ],
                  1
                ),
                _c("ics-page-inner", { attrs: { title: "绑定合同模板" } }, [
                  _c(
                    "div",
                    { staticClass: "partition-table" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingTem.list,
                              expression: "loadingTem.list"
                            }
                          ],
                          ref: "multipleTable",
                          staticClass: "table-input",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.templateList,
                            border: "",
                            "max-height": "360"
                          },
                          on: { "selection-change": _vm.handleSelectionChange }
                        },
                        [
                          _vm.editStatus()
                            ? _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  align: "left",
                                  "min-width": "50"
                                }
                              })
                            : _vm._e(),
                          _vm.viewEdit()
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "contractTemplateNumber",
                                  label: "模板编号",
                                  "min-width": "140",
                                  formatter: _vm.utils.isEffective
                                }
                              })
                            : _vm._e(),
                          !_vm.viewEdit()
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "templateCode",
                                  label: "模板编号",
                                  "min-width": "140",
                                  formatter: _vm.utils.isEffective
                                }
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            attrs: {
                              prop: "contractName",
                              label: "模板名称",
                              "min-width": "140",
                              formatter: _vm.utils.isEffective
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { label: "模板文件", "min-width": "230" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    scope.row.contractUrl
                                      ? _c("span", [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-btn",
                                              attrs: { href: "javascript:" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.utils.downloadP(
                                                    "concat",
                                                    scope.row.contractUrl
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.utils.isEffectiveCommon(
                                                      scope.row.contractUrlName
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      : _c("span", [_vm._v("-")])
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "capName",
                              label: "资金方",
                              "min-width": "160",
                              formatter: _vm.utils.isEffective
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "createdBy",
                              label: "创建人",
                              "min-width": "150",
                              formatter: _vm.utils.isEffective
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "签署方式",
                              "label-class-name": "required-th",
                              align: "center",
                              "min-width": "170"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm.editStatus()
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: { "label-width": "120" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "error",
                                                  fn: function(errScope) {
                                                    return [
                                                      _c(
                                                        "table-form-error-tooltip",
                                                        {
                                                          attrs: {
                                                            "err-scope": errScope
                                                          }
                                                        }
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                model: {
                                                  value:
                                                    scope.row.signingMethod,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "signingMethod",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.signingMethod"
                                                }
                                              },
                                              _vm._l(
                                                _vm.constants.signingMethodType,
                                                function(item) {
                                                  return _c(
                                                    "el-radio",
                                                    {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.value
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.label) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.utils.statusFormat(
                                                Number(scope.row.signingMethod),
                                                "signingMethodType"
                                              )
                                            )
                                          )
                                        ])
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }